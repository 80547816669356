(function ($) {
  window.site = site || {};
  site.specialOffers = site.specialOffers || {};
  Drupal.behaviors.specialOffersFooter = {
    attach: function (context, settings) {
    // Drawers need to be initialized before we can select stuff from them:
      site.drawers.init(context);

      var $tray = $('.special-offers-tray', context);
      var $exclusiveOffer = $('.special-offer');
      var $specialOfferBenefits = $('.special-offer-benefits');
      var $giftWrap = $('.gift-wrap');
      var $myFeedTray = $('.my-feed-tray', context);
      var $emailSignUpForm = $('.offers-email-signup-form', $tray);
      var $registerForm = $('.offers-register-form', $tray);
      var $signInForm = $('.offers-sign-in-form', $tray);
      var $emailSignUpErrors = $('.offers-email-signup-form__errors', $emailSignUpForm);
      var $emailInputs = $('.form-text', $emailSignUpForm);
      var $signInLink = $('.offers-email-signup-form__sign-in-wrapper a', $emailSignUpForm);
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      var signedIn = site.userInfoCookie.getValue('signed_in') - 0;
      var $eListLoyalty = $('.e-list_loyalty', $tray);

      if (signedIn) {
        $eListLoyalty.hide();
      }

      // This would expand the tray only once ever per computer:
      var firstTime = !!persistentCookie.first_time;
      // Instead, the brand has decided to show the drawer again once per session:
      var cookieName = 'elb_tray_session';
      var firstTime = false;

      if (!$.cookie(cookieName)) {
        firstTime = true;
        var domain = '.' + window.location.hostname.replace(
          /^.*(esteelauder\.)/,
          function (m, $1) {
            return $1;
          }
        );

        $.cookie(cookieName, 1, {
          path: '/',
          domain: domain
        });
      }

      var displayDuration = 0;
      var expandOfferTray = $.cookie('expandOfferTray', { path: '/' });

      expandOfferTray = expandOfferTray || firstTime;
      var expandMyFeedTray = $.cookie('expandMyFeedTray', { path: '/' });
      var firstName = persistentCookie.first_name;
      var userDetected = !!firstName;

      // Delete the temporary cookies that tell us what tray to expand
      $.cookie('expandOfferTray', null, { path: '/' });
      $.cookie('expandMyFeedTray', null, { path: '/' });

      if (typeof site.specialOffers.footerDisplayDuration != 'undefined' && !isNaN(site.specialOffers.footerDisplayDuration)) {
        displayDuration = site.specialOffers.footerDisplayDuration;
      }

      // Display the sign in form if the user is recognized but not signed in
      if (userDetected && $('body').hasClass('elc-user-state-anonymous')) {
      // $tray.addClass('sign-in');
        $emailSignUpForm.hide();
        $('.gift-wrap', $tray).show();
        if ($.cookie('expandFeedTray', { path: '/' }) != 'no') {
          $.cookie('expandFeedTray', 'no', { path: '/' });
          setTimeout(function () {
            site.drawers.open($('.my-feed-drawer .drawer-formatter__trigger', context), $myFeedTray.parents('.drawer-formatter__content'), 0, true);
            setTimeout(function () {
              site.drawers.close($myFeedTray.parents('.drawer-formatter__content'));
            }, site.specialOffers.footerDisplayDuration * 1000);
          }, 60);
        } else {
        // console.log("feed tray already shown");
        }
      }

      var returnURL = window.location.pathname;

      $('input[name=RETURN_URL]', $tray).val(returnURL);

      // Display Special offers if user just signed out from My Feed or is visiting
      // for the first time. Display My Feed drawer if user just registered from
      // Special Offers drawer.
      setTimeout(function () {
        if (!displayDuration || (!expandOfferTray && !expandMyFeedTray)) {
          return;
        }

        var $pane, $trigger;

        if (expandOfferTray) {
          $pane = $tray.parents('.drawer-formatter__content');
          $trigger = $('.special-offers-drawer .drawer-formatter__trigger', context);
        } else {
          $pane = $myFeedTray.parents('.drawer-formatter__content');
          $trigger = $('.my-feed-drawer .drawer-formatter__trigger', context);
        }

        site.drawers.open($trigger, $pane, 0, true);
        setTimeout(function () {
          site.drawers.close($pane);
        }, site.specialOffers.footerDisplayDuration * 1000);
      }, 60);

      $signInLink.on('click', function (event) {
        event.preventDefault();
        $tray.addClass('sign-in');
      });

      // Email sign up submit:
      $emailSignUpForm.on('submit', function (event) {
        event.preventDefault();
        $emailSignUpErrors.addClass('hidden');
        $emailInputs.removeClass('error');

        // Transform string into array of form elements
        var params = {};

        $.each($emailSignUpForm.serializeArray(), function (index, kv) {
          params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
        });

        // Send the data via a json rpc call
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [params],
          onSuccess: function (jsonRpcResponse) {
          // Fill out the hidden FIRST_NAME and PC_EMAIL_ADDRESS fields:
            var firstNameVal = $('input[name=FIRST_NAME]', $emailSignUpForm).val();
            var emailVal = $('input[name=PC_EMAIL_ADDRESS]', $emailSignUpForm).val();

            $('input[name=FIRST_NAME]', $registerForm).val(firstNameVal);
            $('input[name=PC_EMAIL_ADDRESS]', $registerForm).val(emailVal);

            // Show the register form (which contains the success message):
            $tray.addClass('register').removeClass('sign-in');
            $emailSignUpErrors.addClass('hidden').hide();

            $(document).trigger('email_signup.success', [jsonRpcResponse]);
          },
          onFailure: function (jsonRpcResponse) {
            var errorObjectsArray = jsonRpcResponse.getMessages();
            var $errListNode = $emailSignUpForm.find('ul.error_messages', $emailSignUpForm);

            $emailSignUpErrors.removeClass('hidden').show();
            generic.showErrors(errorObjectsArray, $errListNode, $emailSignUpForm);

            $(document).trigger('email_signup.error', [jsonRpcResponse]);
          }
        });
      });

      // Registration submit:
      $registerForm.on('submit', function (event) {
        $.cookie('expandMyFeedTray', 1, { path: '/' });
      });

      // Sign in submit:
      $signInForm.on('submit', function (event) {
        $.cookie('expandMyFeedTray', 1, { path: '/' });
      });

      $('.my-feed-summary__signout', context).on('click', function (event) {
        var domain = '.' + window.location.hostname.replace(
          /^.*(esteelauder\.)/,
          function (m, $1) {
            return $1;
          }
        );
        // $.cookie("persistent_user_cookie", null, { path: '/', domain: domain });
        // reset only first name cookie
        var cookieObj = $.parseJSON($.cookie('persistent_user_cookie', {
          path: '/',
          domain: domain
        }));

        cookieObj.first_name = null;
        $.cookie('persistent_user_cookie', JSON.stringify(cookieObj), {
          path: '/',
          domain: domain
        });
        $.cookie('persistent_user_last_purchase', null, { path: '/' });
        $.cookie('expandMyFeedTray', 0, { path: '/' });
        // window.location.reload();
  	  if (!signedIn) {
  	    // event.preventDefault();
  	    // $('button.page-utilities__account-button')[0].click();
  	    $.cookie('expandMyFeedTray', 1, { path: '/' });
  	  }
      });

      $('.special-offers-drawer .drawer-formatter__trigger', context).on('click', function () {
	  var myWindow = window.open('/promotions', '_self');
      });

      // turn user tray block wrapper clickable
      if ($exclusiveOffer.length > 0) {
        var exclusiveOfferLink = $exclusiveOffer.find('a');

        $exclusiveOffer.css('cursor', 'pointer');
        if (exclusiveOfferLink.length > 0) {
          $exclusiveOffer.click(function () {
            window.open(exclusiveOfferLink.attr('href'), '_self');
          });
        }
      }
      if ($specialOfferBenefits.length > 0) {
        var specialOfferBenefitsLink = $specialOfferBenefits.find('a');

        $specialOfferBenefits.css('cursor', 'pointer');
        if (specialOfferBenefitsLink.length > 0) {
          $specialOfferBenefits.click(function () {
            window.open(specialOfferBenefitsLink.attr('href'), '_self');
          });
        }
      }
      if ($giftWrap.length > 0) {
        var giftWrapLink = $giftWrap.find('a');

        $giftWrap.css('cursor', 'pointer');
        if (giftWrapLink.length > 0) {
          $giftWrap.click(function () {
            window.open(giftWrapLink.attr('href'), '_self');
          });
        }
      }
    } // attach
  };
})(jQuery);
